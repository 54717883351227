.t365 {
	.generateReports .panel-heading {
	  background-color: #25282a !important;
	}

	.generateReports .sort-setting-item:hover, .export-setting-item:hover {
	  background-color: #25282a;
	}

	.generateReports .styled-input-single label:after {
	  background: #25282a;
	}

	.generateReports .table .action-button {
		background-color: #f19c49;
	}

	.generateReports .table .action-button:hover {
	  background-color: #25282a;
	}

	.generateReports .table .red-button:hover {
	  background-color: #25282a;
	}

	.generateReports .main-row {
	  background-color: #25282a !important;
	}
}