.customMultiselect {
  position: relative;
  width: 100%;
}

.customMultiselect .select-arrow {
  position: absolute;
  top: 14px;
  right: 11px;
  cursor: pointer;
}

.customMultiselect .select-arrow-open {
  transform: rotate(180deg);
}

.customMultiselect .select-arrow:hover svg {
  fill: #000000;
}

.customMultiselect .select-arrow svg {
  width: 10px;
  height: 10px;
  fill: #999999;
}

/* .customMultiselect .form-control[disabled], */
.customMultiselect .form-control[readonly] {
  cursor: pointer;
  background-color: #FFFFFF;
}

.customMultiselect .multiselect-items {
  position: absolute;
  z-index: 10;
  width: 100%;
  top: 40px;
  max-height: 250px;
  background-color: #FFFFFF;
  border: 1px solid #CCCCCC;
}

.customMultiselect .multiselect-error {
  border: 2px solid #FF0000;
}

.customMultiselect .multiselect-items .search {
  margin: 10px;
}

.customMultiselect .multiselect-items .select-functionality {
  display: flex;
  flex-direction: row;
  height: 40px;
  width: 95%;
  margin: 7px;
  border: 1px solid #CCCCCC;
  border-radius: 10px;
}

.customMultiselect .multiselect-items .select-functionality .select-all {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  cursor: pointer;
  border-right: 1px solid #CCCCCC;
  border-radius: 10px 0 0 10px;
}

.customMultiselect .multiselect-items .select-functionality .deselect-all {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  cursor: pointer;
  border-radius: 0 10px 10px 0;
}

.customMultiselect .multiselect-items .select-functionality .select-all:hover, .deselect-all:hover {
  color: #FFFFFF;
  background-color: #187EED;
}

.customMultiselect .multiselect-items .items {
  margin: 10px;
  width: 95%;
  height: 130px;
  overflow: auto;
}

.customMultiselect .multiselect-items .items .select-item {
  display: block;
  margin-bottom: 3px;
  cursor: pointer;
  position: relative;
  padding: 3px 2px 2px 6px;
}

.customMultiselect .multiselect-items .items .select-item.checked {
  background: linear-gradient(to right, #F0F6FF, #FFFFFF);
  border-radius: 4px;
}

.customMultiselect .multiselect-items .items .select-item:not(.checked):hover {
  box-shadow: 0 2px 4px #F0F0F0;
}

.customMultiselect .multiselect-items .items .select-item .select-checkbox {
  position: absolute;
  right: 0;
}

/* Base for label styling */
.customMultiselect .custom-checkbox:not(:checked),
.custom-checkbox:checked {
  position: absolute;
  left: -9999px;
}

.customMultiselect .custom-checkbox:not(:checked) + label,
.custom-checkbox:checked + label {
  position: relative;
  padding-left: 1.95em;
  cursor: pointer;
}

/* checkbox aspect */
.customMultiselect .custom-checkbox:not(:checked) + label::before,
.custom-checkbox:checked + label::before {
  content: '';
  position: absolute;
  left: 0; top: 0;
  width: 20px; height: 20px;
  border: 2px solid #cccccc;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: inset 0 1px 3px rgb(0 0 0 / 10%);
}

/* checked mark aspect */
.customMultiselect .custom-checkbox:not(:checked) + label::after,
.custom-checkbox:checked + label::after {
  content: '\2713\0020';
  position: absolute;
  top: .15em; left: .22em;
  font-size: 1.3em;
  line-height: 0.8;
  color: #09ad7e;
  transition: all .2s;
  font-family: "Lucida Sans Unicode", "Arial Unicode MS", Arial, sans-serif;
}

/* checked mark aspect changes */
.customMultiselect .custom-checkbox:not(:checked) + label::after {
  opacity: 0;
  transform: scale(0);
}

.customMultiselect .custom-checkbox:checked + label::after {
  opacity: 1;
  transform: scale(1);
}

/* disabled checkbox */
.customMultiselect .custom-checkbox:disabled:not(:checked) + label::before,
.custom-checkbox:disabled:checked + label::before {
  box-shadow: none;
  border-color: #bbbbbb;
  background-color: #dddddd;
}

.customMultiselect .custom-checkbox:disabled:checked + label::after {
  color: #999999;
}

.customMultiselect .custom-checkbox:disabled + label {
  color: #aaaaaa;
}
