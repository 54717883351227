.trustbucks {
  .toggler-container {
    border: 2px solid #263e0f;

    &.active {
      background-color: #32CD32;
    }
  }

  .toggler-container.active {
    background-color: #263e0f;
  }

  .toggler-container .slider {
    background-color: #263e0f;
  }

  .navBar {
    height: 60px;
    background-color: #FFFFFF;
    border-bottom: 1px solid #ccc;
    position: fixed!important;
    top: 0;
    width: 100%;
    z-index: 9;
  }

  .navBar .languages .language {
    cursor: pointer;
    margin-right: 5px;
  }

  .navBar .languages .language.active {
    color: #263e0f;
  }

  .navBar .burger-content .menu .header {
    background-color: #263e0f;
  }

  .navBar .burger-content .menu .menuItem.active {
    color: #263e0f;
  }

  .navBar .burger-content .logout-item {
    border-top: 1px solid #263e0f;
  }

  .navBar .burger-container .burger-bar1,
  .navBar .burger-container .burger-bar2,
  .navBar .burger-container .burger-bar3 {
    background-color: #263e0f;
  }

  .sidebar .header {
    background-color: #263e0f;
  }

  .sidebar .menuItem.active {
    background-color: #f1f1f1;
    color: #263e0f;
  }

  .content .panel-default>.panel-heading {
    background-color: #263e0f;
    border-color: #ddd;
  }

  .btn.defaultBtn {
    background-color: #d29d02;
  }

  .btn.defaultBtn[disabled]:hover {
    background-color: #D29D02;
    color: #FFFFFF;
  }

  .rdtPicker td.rdtToday:before {
    border-bottom: 7px solid #263e0f;
  }
  .rdtPicker td.rdtActive,
  .rdtPicker td.rdtActive:hover {
    background-color: #263e0f;
  }
  
  .multiselect .multiselect-header {
    background-color: #263e0f;
    border: 1px solid #263e0f;
  }

  .multiselect .add {
    background-color: #263e0f;
  }

  .table-component .header-active {
    background-color: #263e0f;
  }

  .json-modal-close {
    background-color: #263e0f;
  }

  .json-modal-close:hover {
    color: #ffffff;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    background-color: #263e0f;
  }

  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background-color: #263e0f;
  }
}