@font-face {
  font-family: 'Titillium Web';
  src: url('../fonts/TitilliumWeb-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizelegibility;
}

body {
  zoom: 0.9;
  font-family: 'Titillium Web', sans-serif;
  margin: 0;
}

.toggler-container {
  height: 20px;
  width: 40px;
  border: 2px solid #223679;
  border-radius: 25% / 50%;
  position: relative;
  cursor: pointer;
  transition-property: background-color;
  transition-duration: 0.5s;

  &.active {
    background-color: #32CD32;

    .slider {
      left: calc(50% + 3px);
      background-color: #FFFFFF;
    }
  }
}

.toggler-container .slider {
  position: absolute;
  width: calc(50% - 4px);
  height: calc(100% - 2px);
  border-radius: 50%;
  top: 1px;
  left: 1px;
  background-color: #223679;
  transition-property: left;
  transition-duration: 0.5s;
}

.navBar {
  height: 60px;
  background-color: #FFFFFF;
  border-bottom: 1px solid #cccccc;
  position: fixed!important;
  top: 0;
  width: 100%;
  z-index: 9;
}

.non-logged-in .navBar-inner {
  justify-content: center !important;
}

.non-logged-in .navBar-inner .header-logo img,
.non-logged-in .navBar-inner .header-logo .logo-text,
.non-logged-in .navBar-inner .header-logo .powered-by {
  margin: 0 !important;
}

.navBar .languages .language {
  cursor: pointer;
  margin-right: 5px;
}

.navBar .languages .language.active {
  color: #187EED;
}

.navBar .navBar-inner {
  width: 100%;
}

.navBar .burger-container {
  display: inline-block;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 13px;
}

.navBar .burger-content {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  top: 0;
  left: -100%;
  border: 0;
  border-radius: 0;
  overflow-x: hidden;
  height: 100%;
  padding: 8px 20px;
  background-color: #FFFFFF;
  transition: 0.4s;
  z-index: 1;
}

.navBar .burger-content.open {
  left: 0;
}

.navBar .burger-content .logo {
  margin-bottom: 10px;
}

.navBar .burger-content .logo .logo-text {
  margin-left: 0;
}

.navBar .burger-content .menu {
  overflow-y: scroll;
  border-top: 1px solid #CCCCCC;
}

.navBar .burger-content .menu .header {
  background-color: #223679;
  color: #FFFFFF;
  padding: 10px 7px 10px 20px;
}

.navBar .burger-content .menu .menuItem {
  padding: 10px 7px 10px 20px;
  text-decoration: none;
  color: #414141;
}

.navBar .burger-content .menu .menuItem.active {
  background-color: #F1F1F1;
  color: #223679;
  font-weight: 700;
}

.navBar .burger-content .logout-item {
  border-top: 1px solid #223679;
}

.navBar .burger-content .logout-item .menuItem {
  display: flex;
  padding: 10px 20px;
  color: #414141;
  text-decoration: none;
}

.navBar .burger-content .menuItem:hover,
.navBar .burger-content .menuItem:focus {
  background-color: #F1F1F1;
  cursor: pointer;
}

.navBar .burger-container .burger-bar1,
.navBar .burger-container .burger-bar2,
.navBar .burger-container .burger-bar3 {
  width: 30px;
  height: 3px;
  background-color: #223679;
  margin: 6px 0;
  transition: 0.4s;
}

.navBar .change .burger-bar1 {
  transform: rotate(-45deg) translate(-9px, 3px);
}

.navBar .change .burger-bar2 {
  opacity: 0;
}

.navBar .change .burger-bar3 {
  transform: rotate(45deg) translate(-9px, -4px);
}

.icon {
  min-width: 70px;
}

.navBar .group-icons {
  margin-right: 15px;
  margin-left: 15px;
}

.navBar .group-icons > div {
   margin-right: 15px;
   cursor: pointer;
}

.navBar .image {
  margin-left: 15px;
}

.navBar .group-icons a {
  text-decoration: none;
  color: #414141;
}

.navBar > a {
  margin-left: 10px;
  margin-right:  10px;
}

.content {
  min-height: 100vh;
  position: relative;
}

.sidebar {
  width: 220px;
  background-color: #FFFFFF;
  z-index: 2;
  padding-top: 60px;
  position: sticky;
  top: 0;
  left: 0;
}

.module {
  background-color: #e0e0e7;
  z-index: 1;
  height: 111%;
}

.sidebar .header {
  background-color: #223679;
  color: #FFFFFF;
  padding: 10px 7px 10px 20px;
}

.sidebar .menublock {
  padding-bottom: 10px;
}

.sidebar .list-menus {
  font-size: 14px;
}

.sidebar .menuItem {
  padding: 10px 7px 10px 20px;
  text-decoration: none;
  color: #414141;
}

.sidebar .menuItem.active {
  background-color: #f1f1f1;
  color: #223679;
  font-weight: bold;
}

.content .panel-default>.panel-heading {
  color: #ffffff;
  background-color: #223679;
  border-color: #dddddd;
}

.content .panel-default>.panel-heading.step2 {
  background-color: #a0a0a0 !important;
}

.content .panel-default {
  min-height: 170px;
}

.form-control,
.Select-control {
  height: 44px;
  min-height: 44px;
  max-width: 100% ;
}

.Select-placeholder, .Select--single > .Select-control .Select-value {
  line-height: 43px;
}

.Select .Select-menu-outer {
  z-index: 5 !important;
}

.btn {
  padding: 11px 12px;
}

.btn.defaultBtn {
  color: #ffffff;
  text-transform: uppercase;
  background-color: #63D311;
  outline: 0;
}

.btn.defaultBtn[disabled]:hover {
  background-color: #63D311;
  color: #FFFFFF;
}

/* POPUP */
.popup-window {
  color: #31708f;
  overflow-wrap: break-word;
  background-color: #d9edf7;
  margin-top: 70px;
  min-width: 300px;
  text-align: center;
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 15px;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 16%),0 2px 10px 0 rgb(0 0 0 / 12%)!important;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #F9F9F9;
}

.table-striped > tbody > tr:nth-of-type(even) {
  background-color: #FFFFFF;
}


/**
 * Date time picker
 *
 */

.rdt {
  position: relative;
}

.rdt .form-control {
  background-color: #FFFFFF;
}

.rdt .form-control[disabled] {
  background-color: #F9F9F9;
}

.rdtPicker {
  display: none;
  position: absolute;
  width: 250px;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #ffffff;
  box-shadow: 0 1px 3px rgb(0 0 0 / 10%);
  border: 1px solid #f9f9f9;
}

.rdtOpen .rdtPicker {
  display: block;
  right: 0;
}

.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static;
}

.rdtPicker .rdtTimeToggle {
  text-align: center;
}

.rdtPicker table {
  width: 100%;
  margin: 0;
}

.rdtPicker td,
.rdtPicker th {
  text-align: center;
  height: 28px;
}

.rdtPicker td {
  cursor: pointer;
}

.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: #eeeeee;
  cursor: pointer;
}

.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
  color: #999999;
}

.rdtPicker td.rdtToday {
  position: relative;
}

.rdtPicker td.rdtToday::before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #428bca;
  border-top-color: rgb(0 0 0 / 20%);
  position: absolute;
  bottom: 4px;
  right: 4px;
}

.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: #428bca;
  color: #ffffff;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 25%);
}

.rdtPicker td.rdtActive.rdtToday::before {
  border-bottom-color: #ffffff;
}

.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker td span.rdtOld {
  color: #999999;
}

.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker th {
  border-bottom: 1px solid #f9f9f9;
}

.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none;
  cursor: default;
}

.rdtPicker th.rdtSwitch {
  width: 100px;
}

.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top;
}

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none; /* iOS Safari */
  user-select: none;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker thead tr:first-child th {
  cursor: pointer;
}

.rdtPicker thead tr:first-child th:hover {
  background: #eeeeee;
}

.rdtPicker tfoot {
  border-top: 1px solid #f9f9f9;
}

.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer;
}

.rdtPicker button:hover {
  background-color: #eeeeee;
}

.rdtPicker thead button {
  width: 100%;
  height: 100%;
}

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer;
}

td.rdtMonth:hover,
td.rdtYear:hover {
  background: #eeeeee;
}

.rdtCounters {
  display: inline-block;
}

.rdtCounters > div {
  float: left;
}

.rdtCounter {
  width: 40px;
  height: 100px;
}

.rdtCounterSeparator {
  line-height: 100px;
}

.rdtCounter .rdtBtn {
  height: 40%;
  line-height: 40px;
  cursor: pointer;
  display: block;
  -webkit-touch-callout: none;
  user-select: none;
}

.rdtCounter .rdtBtn:hover {
  background: #eeeeee;
}

.rdtCounter .rdtCount {
  height: 20%;
  font-size: 1.2em;
}

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px;
}

.rdtMilli input {
  width: 100%;
  font-size: 1.2em;
  margin-top: 37px;
}

.rdtTime td {
  cursor: default;
}

.react-datepicker {
  border-color: #F9F9F9;
  box-shadow: 0 1px 3px rgb(0 0 0 / 10%);
  font-size: 1.3rem !important;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
  min-width: 55px;
}

.react-datepicker__header {
  padding-top: 6px !important;
  background-color: #FFFFFF;
  border-bottom: 0;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-bottom-color: #F9F9F9;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before {
  border-top-color: #F9F9F9;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
  border-bottom-color: #FFFFFF;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::after {
  border-top-color: #FFFFFF;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #223679;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #223679;
}

.react-datepicker__month-container {
  float: left;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  border-bottom-right-radius: 0;
  width: 70px;
}

.react-datepicker__time-container {
  width: 70px;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 70px;
}

.react-datepicker-popper {
  min-width: 282px;
}

.calendar-svg {
  width: 20px;
  height: 20px;
  fill: #C0C0C0;
  position: absolute;
  top: 37px;
  left: 10px;
  z-index: 1;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-size: 1.5rem !important;
}

.react-datepicker__navigation {
  top: 5px !important;
}

.react-datepicker__day-name,
.react-datepicker__day {
  margin: 0.5rem !important;
}

/* Multiselect styles */

.multiselect {
  width: 100%;
}

.multiselect .multiselect-header {
  background-color: #223679;
  color: #FFFFFF;
  padding: 5px 5px 5px 15px;
  border: 1px solid #223679;
  border-radius: 10px;
  width: 100%;
}

.multiselect .multiselect-body {
  margin: 15px 5px 0;
  width: 100%;
}

.multiselect .multiselect-select {
  width: 100%;
}

.multiselect .form-control {
  padding: 6px 0 6px 12px;
}

.multiselect .selected-items {
  margin-top: 10px;
}

.multiselect .actions {
  width: 150px;
  margin-left: 10px;
}

.multiselect .add, .delete {
  width: 30px;
  margin: 6px;
  outline: 0;
}

.multiselect .error-message {
  margin: 2px;
  color: #FF0000;
}

.multiselect .add .plus {
  position: absolute;
  top: 1px;
  left: 10px;
}

.multiselect .add {
  position: relative;
  border-radius: 50%;
  box-shadow: 0;
  border: 0;
  font-size: 18px;
  font-weight: bold;
  height: 30px;
  background-color: #223679;
  color: #FFFFFF;
}

.multiselect .delete {
  border-radius: 50%;
  box-shadow: 0;
  border: 0;
  font-size: 12px;
  font-weight: bold;
  height: 30px;
  background-color: #CCCCCC;
  color: #FFFFFF;
}

.swal2-popup {
  font-size: 1.6rem !important;
}

.swal2-title {
  text-transform: capitalize !important;
}

.header-logo:hover {
  text-decoration: none !important;
}

.header-logo.active {
  text-decoration: none !important;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
}

input[type="number"] {
    appearance: textfield;
}

/* .table-component table {
  table-layout: fixed;
} */

.table-component table > thead > tr > th {
  cursor: pointer;
  vertical-align: middle;
}

.table-component .icon-span, .text-span {
  display: inline-block;
  vertical-align: middle;
}

.table-component .header-active {
  background-color: #223679;
  color: #FFFFFF;
}

.swal2-icon.swal2-warning::before {
  content: '' !important;
}

.json-modal-close {
  width: 200px;
  background-color: #223679;
  color: #FFFFFF;
}

.form-control[disabled] {
  appearance: none;
  background-color: #F9F9F9;
}

code.json-view {
  padding: 0;
  font-size: 100%;
  background-color: unset;
  border-radius: unset;
}

.json-view .jv-indent {
  padding-left: 0;
}

.json-view .json-view--pair {
  padding: 3px 5px 3px 20px;
  border-left: 1px solid #EBEBEB;
}

.json-view  .json-view--property {
  color: #002B36;
}

.json-view  .json-view--string {
  color: #CB4B16;
  word-wrap: break-word;
}


/* View-only mode styles  */

.logged-as-user {
  position: fixed !important;
  top: 0;
  width: 100%;
  z-index: 10;
  align-items: center;
  background-color: #E5E5E5;
  padding: 7px 15px;
}

.logged-as-user .title {
  font-size: 14px;
  margin: 0;
}

.logged-as-user .title span {
  font-size: 16px;
  font-style: italic;
  font-weight: 700;
}

.logged-as-user .close-btn {
  background-color: #000000;
  border: 0;
  border-radius: 4px;
  color: #FFFFFF;
  margin-left: auto;
  padding: 5px 10px;
}

.content.view-only .navBar,
.content.view-only .navBar .burger-content {
  top: 44px;
}

.content.view-only .sidebar {
  padding-top: 104px;
}

.content.view-only .module .header {
  margin-top: 104px;
}

.content.view-only .navBar .group-icons .logout-icon,
.content.view-only .generateReports .panel-heading .settings-panel .setting,
.content.view-only .generateReports .panel-content td.history,
.content.view-only .generateReports .panel-content td button.action-button,
.content.view-only .my-account .btn.defaultBtn,
.content.view-only .sandbox .btn.defaultBtn,
.content.view-only .request-settlement .btn.defaultBtn,
.content.view-only .navBar .burger-content .logout-item .menuItem {
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
}

.content.view-only .my-account input.form-control {
  pointer-events: none;
  cursor: not-allowed;
  appearance: none;
  background-color: #F9F9F9;
}

/* Responsive styles */

@media screen and (width >= 992px) {
  .two-inputs-column {
    width: 65%;
  }

  .fxSettings .input-column {
    width: 220px;
  }

  .wrap-rule br {
    display: none;
  }

  .navBar .burger-container {
    display: none;
  }

  .logo-text {
    margin-left: 20px;
  }

  .powered-by {
    margin-left: 20px;
  }
}

@media screen and (width <= 1199px) {
  .two-inputs-column {
    width: 65%;
  }

  .fxSettings .input-column {
    width: 220px;
  }

  .wrap-rule br {
    display: none;
  }

  .addClient .extra-column {
    display: none !important;
  }

  .editClient .extra-column {
    display: none !important;
  }
}

@media screen and (width <= 991px) {
  .content .group-icons {
    display: none !important;
  }

  .navBar .burger-container {
    left: 15px;
    right: unset;
  }

  .navBar .burger-container.change {
    left: unset;
    right: 15px;
    top: 13px;
    z-index: 2;
  }

  .navBar .navBar-inner {
    justify-content: center !important;
  }

  .navBar .navBar-inner .logo-text {
    margin-left: 0;
  }

  .navBar .navBar-inner .header-logo img {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .navBar .burger-content .header-logo.t365 img {
    margin: 15px 3px 15px 0 !important;
  }

  .navBar .burger-content .header-logo.fxmb img,
  .navBar .burger-content .header-logo.tlapay img {
    margin-left: 0 !important;
  }

  .navBar .burger-content .header-logo.tlapay img {
    margin-top: 5px !important;
  }

  .sidebarColumn {
    display: none !important;
  }

  /* .apidata .revenueBoxes, .apidata .input-column {
    width: 49%
  } */
  .fxSettings .input-column {
    width: 100%;
    margin-right: 0;
  }

  .two-inputs-column {
    width: 100%;
  }

  .big-wrap-row {
    margin: 0;
  }

  .big-wrap-row img {
    max-width: 265px;
  }

  .two-inputs-column .input-column:first-of-type {
    margin-right: 10px;
  }

  .navBar .languages {
    margin-right: 50px;
  }

  .logo-text {
    font-size: 22px;
    margin-left: 5px;
  }

  .powered-by {
    margin-left: 5px;
  }
}

@media screen and (width <= 767px) {
  .fxFAQ .input-column:first-of-type {
    margin-right: 2%;
  }

  .multiselect .multiselect-select {
    width: 100%;
  }

  .logged-as-user .title span {
    display: block;
  }
}

@media screen and (width <= 500px) {
  .json-modal-close {
    width: 100%;
  }
}

@media screen and (width >= 850px) {
  .modal-dialog {
    width: 800px !important;
  }
}