.trustbucks {
  .apidata .revenueBoxes {
    background-image: linear-gradient(#263e0f, #384e24);
  }

  .apidata .revenue-details {
    width: 100%;
    -webkit-box-shadow: 0px -5px 6px 0px #4c6c2f;
    -moz-box-shadow: 0px -5px 6px 0px #4c6c2f;
    box-shadow: 0px -5px 6px 0px #4c6c2f;
    background-color: #4c6c2f;
  }

  .apidata .panel-heading {
    background-color: #263e0f!important;
  }

  .dashboard-user-item {
    background-image: linear-gradient(#263e0f, #1b60ab);
  }

  .logo-text {
    color: #263e0f;
  }
}
  