.pacificCapital {
  .apidata .revenueBoxes {
    background-image: linear-gradient(#2b637c, #4b4d48);
  }

  .apidata .revenue-details {
    width: 100%;
    -webkit-box-shadow: 0px -5px 6px 0px #2b637c;
    -moz-box-shadow: 0px -5px 6px 0px #2b637c;
    box-shadow: 0px -5px 6px 0px #2b637c;
    background-color: #2b637c;
  }

  .apidata .panel-heading {
    background-color: #2b637c!important;
  }

  .dashboard-user-item {
    background-image: linear-gradient(#2b637c, #4b4d48);
  }

  .logo-text {
    color: #2b637c;
  }
}
  