.generateReports .header {
  width: 100%;
  background-color: #f9f9f9;
  height: 70px;
  margin-top: 60px;
}

.generateReports .panel-content {
  overflow: auto;
}

.generateReports .panel-block {
  width: 100%;
  padding: 15px 15px 0;
}

.generateReports .input-column {
  width: 180px;
  margin: 10px;
  position: relative;
}

.generateReports .panel-heading {
  position: relative;
}

.generateReports .error-field {
  border: 2px solid #FF0000;
  border-radius: 7px;
}

.generateReports .settings-panel {
  position: absolute;
  width: 200px;
  right: 50px;
  top: 10px;
}

.generateReports .settings-panel .setting {
  cursor: pointer;
}

.generateReports .settings-panel .setting .setting-icon, .text {
  vertical-align: middle;
  display: inline-block;
}

.generateReports .settings-panel .setting .setting-icon {
  width: 17px;
  height: 17px;
  margin-right: 5px;
  fill: #FFFFFF;
}


.generateReports .sort-settings, .export-settings {
  position: absolute;
  top: 40px;
  width: 120px;
  z-index: 100;
  background-color: #FFFFFF;
  border-radius: 10px;
}

.generateReports .sort-settings {
  right: 140px;
}

.generateReports .export-settings {
  right: 15px;
}

.generateReports .sort-setting-item, .export-setting-item {
  margin: 10px;
  color: #000000;
  padding: 3px 3px 3px 5px;
  cursor: pointer;
  border-radius: 10px;
}

.generateReports .sort-setting-item:hover, .export-setting-item:hover {
  color: #FFFFFF;
  background-color: #187eed;
}

/* icon for multiselect */

.generateReports .datetime-container .form-control,
.generateReports .react-datepicker-wrapper .form-control {
  padding-left: 35px !important;
}

.generateReports .Select .Select-menu-outer {
  z-index: 2 !important;
}


.generateReports input[type="checkbox"] {
  position: absolute;
  top: 0;
  left: -9999px;
  visibility: hidden;
}

.generateReports input[type="checkbox"]:checked + label::after {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.generateReports .styled-input-single {
  position: relative;
  margin: 10px;
}

.generateReports .styled-input-single label {
  cursor: pointer;
  margin-left: 35px;
}

.generateReports .styled-input-single label::before, label::after {
  content: '';
  position: absolute;
  top: 50%;
  border-radius: 50%;
}

.generateReports .styled-input-single label::before {
  left: 0;
  width: 25px;
  height: 25px;
  margin: -15px 0 0;
  background: #FFFFFF;

  /* box-shadow: 0 0 1px #808080;
  -webkit-appearance: none;
  -webkit-box-shadow: 0 0 1px #808080; */
  border: 1px solid #E0E0E0;
}

.generateReports .table .grey-button {
  height: 35px;
  width: 100px;
  background-color: grey !important;
  border-radius: 5px;
  display: block;
  color: #FFFFFF;
  margin: auto;
  padding: 7px;
  outline: none;
  border: none;
  pointer-events: none;
}

.generateReports .styled-input-single label::after {
  left: 5px;
  width: 15px;
  height: 15px;
  margin: -10px 0 0;
  opacity: 0;
  background: #223679;
  transform: translate3d(-40px, 0, 0) scale(0.5);
  transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
}

.generateReports .styled-input--square label::before, label::after {
  border-radius: 0;
}

.generateReports .report-content {
  overflow: auto;
  width: 100%;
  position: relative;
}

.generateReports .table td {
  word-break: break-all;
}

.generateReports .table th,
.generateReports .table td {
  vertical-align: middle !important;
  min-width: 100px;
}

.generateReports .table .action-button {
  height: 35px;
  width: 100px;
  background-color: #3BE13B;
  border-radius: 5px;
  display: block;
  color: #FFFFFF;
  margin: auto;
  padding: 7px;
  border: none;
  outline: none;
}

.generateReports .table .action-button:hover {
  background-color: #223679;
}

.generateReports .report-content.performance .table .action-button.orange,
.generateReports .report-content.performance .table .action-button.green {
  pointer-events: none;
}

.generateReports .table .action-button.orange,
.generateReports .table .action-button.orange:hover {
  background-color: #EAAA00;
}

.generateReports .table .action-button.green:hover {
  background-color: #3BE13B;
}

.generateReports .table .red-button {
  height: 35px;
  width: 100px;
  background-color: #E74C3C;
  border-radius: 5px;
  display: block;
  color: #FFFFFF;
  margin: auto;
  padding: 7px;
  outline: none;
  border: none;
}

.generateReports .table .red-button:hover {
  background-color: #223679;
}


.generateReports .table .no-history {
  color: #D9D9D9;
  pointer-events: none;
}

.generateReports .table .history {
  text-decoration: underline;
  cursor: pointer;
  pointer-events: auto;
  text-align: center;
}

.generateReports .main-row {
  background-color: #223679 !important;
  color: #FFFFFF;
}

.generateReports .usual-row {
  background-color: #FFFFFF !important;
}


.generateReports .calendar-svg {
  width: 20px;
  height: 20px;
  fill: #C0C0C0;
  position: absolute;
  top: 37px;
  left: 10px;
  z-index: 1;
}

@media screen and (width <= 1300px) {
  .generateReports .table td,
  .generateReports .table th {
    min-width: 125px !important;
    word-break: break-all;
  }
}

@media screen and (width >= 501px) and (width <= 1300px) {
  .generateReports .table td:nth-child(1),
  .generateReports .table th:nth-child(1) {
    position: sticky;
    left: -2px;
    z-index: 3;
    background-color: inherit;
  }
}

@media screen and (width <= 500px) {
  .generateReports .empty-column {
    display: none !important;
  }

  .generateReports .panel {
    max-width: calc(var(--vw, 1vw) * 100) !important;
  }

  .generateReports .report-content {
    width: calc(var(--vw, 1vw) * 100 - 30px);
  }

  .generateReports .report-content .table thead > tr {
    position: sticky;
    left: 0;
    top: 0;
    z-index: 50;
    display: block;
  }

  .generateReports .report-content .table tbody {
    display: block;
  }

  .generateReports .report-content .table tr td:first-child,
  .generateReports .report-content .table tr th:first-child {
    position: sticky;
    left: 0;
    z-index: 40;
    background-color: inherit;
  }

  .generateReports .settings-panel {
    position: absolute;
    width: 175px;
    right: 0;
    top: 10px;
  }
}

@media screen and (width >= 501px) and (width <= 991px) {
  .generateReports .report-content {
    width: calc(var(--vw, 1vw) * 100);
  }

  .pagination {
    padding-left: 23% !important;
  }
}

@media screen and (width >= 992px) and (width <= 1100px) {
  .generateReports .report-content {
    width: calc(var(--vw, 1vw) * 100 - 190px);
  }

  .pagination {
    padding-left: 30%!important;
  }
}

@media screen and (width >= 1101px) and (width <= 1300px) {
  .generateReports .report-content {
    width: calc(var(--vw, 1vw) * 100 - 180px);
  }

  .pagination {
    padding-left: 30%!important;
  }
}

@media screen and (width >= 1301px) and (width <= 1500px) {
  .generateReports .report-content {
    width: calc(var(--vw, 1vw) * 100 - 150px);
  }

  .pagination {
    padding-left: 35%!important;
    padding-right: 15%!important;
  }
}

@media screen and (width >= 1501px) and (width <= 1650px) {
  .generateReports .report-content {
    width: calc(var(--vw, 1vw) * 100 - 130px);
  }

  .pagination {
    padding-left: 30%!important;
  }
}

@media screen and (width >= 1651px) and (width <= 1800px) {
  .generateReports .report-content {
    width: calc(var(--vw, 1vw) * 100 - 115px);
  }
}

@media screen and (width >= 1651px) and (width <= 1920px) {
  .generateReports .report-content {
    width: calc(var(--vw, 1vw) * 100 - 100px);
  }

  .pagination {
    padding-left: 38%!important;
  }
}

@media screen and (width >= 1930px) {
  .pagination {
    padding-left: 40%!important;
  }
}

.swal2-popup #swal2-content {
  max-height: 18em;
  overflow-y: auto;
}


