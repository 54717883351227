.login {
    margin-top: 60px;
    padding: 15px;
}

.loginPanel {
    width: 600px;
    margin: 0 auto;
}

@media (width <= 700px) {
    .loginPanel {
        width: 100%;
    }
}

.loginForm .inputWrap {
    margin-bottom: 15px;
}

.loginForm .inputWrap.codeWrap {
  margin-top: 20px;
}

.loginForm .defaultBtn {
    width: 100%;
}

.loginForm .recaptcha {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 15px;
}

.swal2-popup {
  font-size: 1.6rem !important;
}

.loginForm .two-fa-form img.qr-code {
  width: 150px;
  height: 150px;
  margin: 20px 0;
}

.loginForm .two-fa-form .buttons {
  display: flex;
  justify-content: space-around;
}

.loginForm .two-fa-form .buttons a.btn {
  margin-top: 15px;
  min-width: 120px;
  padding: 0;
  border-radius: 15px;
  background: transparent;
}

.loginForm .two-fa-form .buttons a.btn img {
  max-width: 150px;
}

.loginForm .inputWrap label {
  width: 100%;
}

.loginForm .inputWrap label .glyphicon {
  margin-left: 6px;
}

.loginForm .inputWrap label input {
  margin-top: 8px;
}
