.trustbucks {
	.generateReports .panel-heading {
	  background-color: #263e0f !important;
	}

	.generateReports .sort-setting-item:hover, .export-setting-item:hover {
	  background-color: #263e0f;
	}

	.generateReports .styled-input-single label:after {
	  background: #263e0f;
	}

	.generateReports .table .action-button {
		background-color: #f5c025;
	}

	.generateReports .table .action-button:hover {
	  background-color: #263e0f;
	}

	.generateReports .table .red-button:hover {
	  background-color: #263e0f;
	}

	.generateReports .main-row {
	  background-color: #263e0f !important;
	}
}