.apidata .header {
  width: 100%;
  background-color: #f9f9f9;
  height: 70px;
  margin-top: 60px;
}

.apidata .input-column {
  width: 18%;
  min-width: 180px;
  margin: 10px;
  position: relative;
}

.apidata .filter-row {
  width: 100%;
}

.apidata .first-datepicker .rdtOpen .rdtPicker {
  left: 0;
}

.apidata .datetime-container .form-control,
.apidata .react-datepicker-wrapper .form-control {
  padding-left: 35px !important;
}

.apidata .revenue-boxes-wrap {
  width: calc((100% * 1/3) - 10px);
  min-width: 290px;
  margin: 5px;
}

.apidata .revenue-boxes-wrap .info {
  width: 66%;
}

.apidata .revenue-boxes-wrap .chart-div {
  width: 33%;
}

.apidata .revenueBoxes .currency-numbers {
  padding: 0;
  margin: 0 0 0 15px;
}

.apidata .revenueBoxes {
  width: 100%;
  min-height: 125px;
  max-height: 230px;
  transition: height 1s ease-in-out;
  padding-top: 10px;
  color: #FFFFFF;
  border-radius: 5px;
  background-image: linear-gradient(#223679, #1b60ab);
}

.apidata .revenue-details {
  width: 100%;
  box-shadow: 0 -5px 6px 0 rgb(101 129 198 / 100%);
  background-color: rgb(101 129 198 / 100%);
  margin-top: 10px;
}

.apidata .revenueBoxes .revenue-toggler {
  cursor: pointer;
}

.apidata .revenue-boxes-wrap .revenue-table {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.apidata .revenue-boxes-wrap table {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
  border-spacing: 10px 0;
  margin: 0;
}

.apidata .revenue-boxes-wrap table th {
  color: #FFFFFF;
  text-align: center;
  font-size: 14px;
  border: 0;
  padding: 0;
}

.apidata .revenue-boxes-wrap table td {
  font-size: 17px;
  text-align: center;
}

.apidata .proximity {
  height: 7px;
  background: linear-gradient(90deg,red,yellow,green);
  margin-left: 10%;
  margin-right: 10%;
  border-radius: 3px;
}

.apidata .proximity .proximity-remain {
  float: right;
  height: 100%;
  background: #4E5B6C;
  border-radius: 0 3px 3px 0;
}

.apidata #myProgress {
  width: 100%;
  height: 7px;
  margin-top: 5px;
  border-radius: 5px;
  background: linear-gradient(90deg,red,yellow,green);
}

.apidata #myBar {
  width: 100%;
  float: right;
  height: 7px;
  border-radius: 5px;
  background: #084f9d;
}

.apidata #myBar.noRadius {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.apidata .balance-container {
  color: #FFFFFF;
  border-radius: 4px;
}

.apidata .table-column {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}

.apidata .table-column table > tbody > tr > td:last-child,
.apidata .table-column table > thead > tr > th:last-child {
  text-align: end;
}

.apidata .apidata .panel-content {
  overflow: auto;
}

.apidata .panel-content tbody td {
  border: 0;
}

.apidata .allSides {
  box-shadow: 0 0 50px  rgb(46 204 113 / 100%);
}

.dashboard-user-item {
  color: #FFFFFF;
  width: 22%;
  height: 100%;
  border-radius: 5px;
  font-size: 16px;
  text-align: center;
  padding: 10px;
  background-image: linear-gradient(#223679, #1b60ab);
}

.logo-text:hover {
  text-decoration: none !important;
}

.logo-text.active {
  text-decoration: none !important;
}

.apidata .revenueBoxes .arrow {
  width: 20px;
  height: 20px;
  fill: #FFFFFF;
}

.apidata .revenueBoxes .arrow-up {
  transform: rotate(180deg);
}

.apidata .error-field {
  border: 2px solid #FF0000;
  border-radius: 7px;
}

.apidata .error-message {
  font-size: 14px;
  color: red;
  margin-top: 2px;
}


.circle-chart__circle {
  animation: circle-chart-fill 2s reverse;
}

.circle-chart__circle--negative {
  transform: rotate(-90deg) scale(1,-1);
}

.circle-chart__info {
  animation: circle-chart-appear 2s forwards;
  opacity: 0;
  transform: translateY(0.3em);
}

@keyframes circle-chart-fill {
  to { stroke-dasharray: 0 534; }
}

@keyframes circle-chart-appear {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.grid {
  display: grid;
  grid-gap: 1em;
  grid-template-columns: repeat(1, 1fr);
}

.apidata .revenue-table table {
  border-collapse: separate;
  border-spacing: 0 10px;
  font-size: 18px;
  margin-bottom: 0;
}

.apidata .revenue-table table thead tr {
  background-color: transparent !important;
}

.apidata .revenue-table table thead tr th {
  border: 1px solid #223579 !important;
  border-left-width: 0 !important;
  border-right-width: 0 !important;
  color: #223579;
}

.apidata .revenue-table table thead tr th:first-child {
  border-left-width: 1px !important;
  border-radius: 4px 0 0 4px;
  padding-left: 15px;
}

.apidata .revenue-table table thead tr th:last-child {
  border-right-width: 1px !important;
  border-radius: 0 4px 4px 0;
  padding-right: 40px;
}

.apidata .revenue-table table tbody {
  color: #FFFFFF;
}

.apidata .revenue-table table tbody tr td {
  margin: 5px 0;
  padding: 10px 8px;
}

.apidata .revenue-table table tbody tr td:first-child {
  border-radius: 4px 0 0 4px;
  padding-left: 15px;
}

.apidata .revenue-table table tbody tr td:last-child {
  border-radius: 0 4px 4px 0;
  padding-right: 40px;
}

.apidata .revenue-table table tbody tr:nth-child(1),
.apidata .revenue-table-mobile .single-item:nth-child(1) {
  background-color: #0B1641;
}

.apidata .revenue-table table tbody tr:nth-child(2),
.apidata .revenue-table-mobile .single-item:nth-child(2) {
  background-color: #16255D;
}

.apidata .revenue-table table tbody tr:nth-child(3),
.apidata .revenue-table-mobile .single-item:nth-child(3) {
  background-color: #223579;
}

.apidata .revenue-table table tbody tr:nth-child(4),
.apidata .revenue-table-mobile .single-item:nth-child(4) {
  background-color: #3A4F9B;
}

.apidata .revenue-table table tbody tr:nth-child(5),
.apidata .revenue-table-mobile .single-item:nth-child(5) {
  background-color: #4A6AB5;
}

.apidata .revenue-table table tbody tr:nth-child(6),
.apidata .revenue-table-mobile .single-item:nth-child(6) {
  background-color: #6485D0;
}

.apidata .revenue-table-mobile {
  display: none;
}

.apidata .revenue-table-mobile .single-item {
  margin-bottom: 10px;
  border-radius: 4px;
  color: #FFFFFF;
  font-size: 26px;
}

.apidata .revenue-table-mobile .single-item .item-header {
  padding: 15px 10px;
  font-size: 24px;
}

.apidata .revenue-table table tbody tr td .currency-symbol,
.apidata .revenue-table-mobile .single-item .item-header .currency-symbol {
  border: 1px solid;
  border-radius: 50%;
  margin-right: 10px;
  display: inline-block;
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 34px;
}

.apidata .revenue-table table tbody tr td .currency-symbol {
  width: 22px;
  height: 22px;
  line-height: 21px;
  margin-right: 5px;
  font-size: 12px;
  position: relative;
  top: -2px;
}

.apidata .revenue-table-mobile .single-item:nth-child(1) .item-header {
  border-bottom: 1px solid #213068;
}

.apidata .revenue-table-mobile .single-item:nth-child(2) .item-header {
  border-bottom: 1px solid #293A7A;
}

.apidata .revenue-table-mobile .single-item:nth-child(3) .item-header {
  border-bottom: 1px solid #324791;
}

.apidata .revenue-table-mobile .single-item:nth-child(4) .item-header {
  border-bottom: 1px solid #6082D1;
}

.apidata .revenue-table-mobile .single-item:nth-child(5) .item-header {
  border-bottom: 1px solid #7999E2;
}

.apidata .revenue-table-mobile .single-item:nth-child(6) .item-header {
  border-bottom: 1px solid #7999E2;
}

.apidata .revenue-table-mobile .single-item .item-body {
  padding: 30px 75px 30px 50px;
}

.apidata .revenue-table-mobile .single-item p {
  color: #6877AE;
  font-size: 16px;
}

.apidata .revenue-table-mobile .single-item:nth-child(4) p,
.apidata .revenue-table-mobile .single-item:nth-child(5) p,
.apidata .revenue-table-mobile .single-item:nth-child(6) p {
  color: #ABB8E2;
}

.apidata .table-content.accordion-table table {
  border-collapse: collapse;
}

.apidata .table-content.accordion-table table thead th {
  background-color: #223579;
  color: #FFFFFF;
  border-bottom: 0;
}

.apidata .table-content.accordion-table table thead th:first-child {
  border-radius: 4px 0 0 4px;
}

.apidata .table-content.accordion-table table thead th:last-child {
  border-radius: 0 4px 4px 0;
}

.apidata .table-content.accordion-table table tr th:last-child,
.apidata .table-content.accordion-table table tr td:last-child {
  max-width: 15px;
}

.apidata .table-content.accordion-table table tr:not(.child-row) td {
  background-color: #FFFFFF;
  color: #223579;
  vertical-align: middle;
  cursor: pointer;
  position: relative;
  border-top: 14px solid transparent !important;
}

.apidata .table-content.accordion-table table tr.even:not(.child-row) td {
  background-color: #F5F5F5;
}

.apidata .table-content.accordion-table table tr.parent-row + tr.child-row:not(.hidden-row) td {
  position: relative;
  border-top: 10px solid transparent !important;
}

.apidata .table-content.accordion-table table tr:not(.child-row) td::before {
  content: "";
  width: auto;
  height: 100%;
  display: block;
  position: absolute;
  left:  -1px;
  right: -1px;
  top: 0;
  box-sizing: border-box;
  border-top: 1px solid #A6B3D0;
  border-bottom: 1px solid #A6B3D0;
}

.apidata .table-content.accordion-table table tr:not(.child-row) td:first-child::before {
  border-left: 1px solid #A6B3D0;
  border-radius: 4px 0 0 4px;
}

.apidata .table-content.accordion-table table tr:not(.child-row) td:last-child::before {
  border-right: 1px solid #A6B3D0;
  border-radius: 0 4px 4px 0;
}

.apidata .table-content.accordion-table table tr td span.mids {
  display: inline-block;
  background-color: #6485D0;
  border-radius: 4px;
  color: #FFFFFF;
  padding: 3px;
  width: 54px;
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
}

.apidata .table-content.accordion-table table tr.child-row.fade.hidden-row {
  visibility: collapse;
}

.apidata .table-content.accordion-table table tr.child-row.fade.in {
  visibility: visible;
}

.apidata .table-content.accordion-table table tr.child-row td {
  background-color: #FCFCFC;
  color: #223579;
  vertical-align: middle;
}

.apidata .table-content.accordion-table table tr.child-row.hidden-row td {
  overflow: hidden;
  line-height: 0;
  padding: 0 8px;
}

.apidata .table-content.accordion-table table tr.child-row.even td {
  background-color: #F5F5F5;
}

@media screen and (width >= 992px) and (width <= 1199px) {
  .apidata .revenue-table table {
    font-size: 14px;
  }
}

@media screen and (width <= 650px) {
  .apidata .revenue-boxes-wrap {
    flex-grow: 1;
  }
}

@media screen and (width >=725px) and (width <=1110px) {
  .apidata .revenue-boxes-wrap .chart-div {
    width: calc(33% - 10px);
    margin-right: 10px;
  }

  .apidata .revenue-boxes-wrap {
    min-width: 255px;
  }
}


@media screen and (width <=1300px) {
  .grid {
    margin: 0 20px 10px 0;
  }

  .apidata .revenue-boxes-wrap {
    width: calc((100% * 1/2) - 10px);
  }
}

/* @media (min-width: 31em) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
} */

.logo-text {
  font-size: 26px;
  font-weight: 900;
  color: #223679;
  line-height: 30px;
  text-transform: uppercase;
}

.starmids-logo {
  width: 20px;
  height: 20px;
  margin-bottom: 5px;
}

.powered-by {
  color: #888888;
}

@media screen and (width <= 500px) {
  .apidata .table-column .table-content {
    width: calc(var(--vw, 1vw) * 100 - 20px);
    overflow: auto;
  }

  .apidata .table-column .table-content .table-component td,
  th {
    min-width: 75px;
    font-size: 12px;
  }

  .apidata .panel {
    max-width: calc(var(--vw, 1vw) * 100 + 5px) !important;
  }

  .apidata .payout-requests .table-component table {
    min-width: 600px;
  }
}


@media screen and (width <= 991px) {
  .apidata .dashboard-row {
    flex-direction: column !important;
  }

  .apidata .table-column,
  .apidata .balance {
    width: 100% !important;
    margin-bottom: 15px;
  }

  .apidata .revenue-table table {
    font-size: 14px;
  }
}

@media screen and (width <= 767px) {
  .apidata .fullwidth-button {
    width: 100%;
  }

  .apidata .defaultBtn {
    width: 100%;
  }

  .apidata .revenue-table {
    display: none;
  }

  .apidata .revenue-table-mobile {
    display: block;
  }

  .apidata .input-column {
    max-width: unset;
  }

  .apidata .table-column .table-content {
    width: calc(var(--vw, 1vw) * 100 - 20px);
    overflow: auto;
  }

  .apidata .table-column .table-content .table-component td,
  .apidata .table-column .table-content .table-component th {
    min-width: 75px;
    font-size: 12px;
  }

  .apidata .table-column .table-content .table-component td:last-child,
  .apidata .table-column .table-content .table-component th:last-child {
    min-width: unset;
  }

  .apidata .panel {
    max-width: calc(var(--vw, 1vw) * 100 + 10px) !important;
  }

  .apidata .payout-requests .table-component table {
    min-width: 600px;
  }

  .apidata .revenue-table-mobile .single-item {
    font-size: 5vw;
  }

  .apidata .revenue-table-mobile .single-item .item-body {
    padding: 15px 20px;
  }
}

@media screen and (width <= 600px) {
  .apidata .filter-row {
    flex-direction: column !important;
  }

  .apidata .input-column {
    width: 100%;
    padding: 0 15px;
    margin: 10px 0;
  }

  .apidata .input-column .calendar-svg {
    left: 25px;
  }

  .apidata .empty-column {
    display: none !important;
  }
}

@media screen and (width <= 499px) {
  .apidata .revenue-table-mobile .single-item {
    font-size: 18px;
  }

  .apidata .revenue-table-mobile .single-item .item-header {
    font-size: 20px;
    padding: 10px;
  }

  .apidata .revenue-table-mobile .single-item .item-body {
    padding: 15px 10px;
  }

  .apidata .revenue-table-mobile .single-item p {
    font-size: 12px;
  }

  .apidata .table-content.accordion-table table tr td span.mids {
    width: 65px;
  }
}

@media screen and (width <= 359px) {
  .apidata .revenue-table-mobile .single-item {
    font-size: 14px;
  }
}
