.refunds .input-column {
    min-width: 180px;
}

.refunds table .edit-refund {
    height: 35px;
    width: 70px;
    background-color: #FF0000;
    border: 1px solid #D3D3D3;
    border-radius: 5px;
    padding: 7px;
    color: #000000;
    display: block;
    margin: auto;
}

.refunds table .edit-refund:hover {
    background-color: #223679;
    color: #FFFFFF;
}

.refunds .pagination-button {
    width: 100px;
    margin: 10px;
}

.refunds .pagination-button:hover {
    background-color: #223679;
    color: #FFFFFF;
}

.refunds .transactions-pagination-count {
    position: absolute;
    top: 12px;
    right: 10px;
}

.refunds .table-content {
    width: 100%;
    overflow: auto;
    position: relative;
}

@media screen and (width <= 500px) {
    .refunds .input-column {
        width: 100%;
        flex-grow: 1;
    }

    .refunds .table-content {
        width: calc(var(--vw, 1vw) * 100 - 30px);
    }

    .refunds .table-content .table {
        width: auto;
    }

    .refunds .table-content .table thead > tr {
        position: sticky;
        left: 0;
        top: 0;
        z-index: 50;
        display: block;
      }

      .refunds .table-content .table tbody {
        display: block;
      }

      .refunds .table-content .table tr td:first-child,
      .refunds .table-content .table tr th:first-child {
        position: sticky;
        left: 0;
        z-index: 40;
        background-color: inherit;
      }
}

@media screen and (width >= 501px) and (width <= 750px) {
    .refunds .table-content {
        width: calc(var(--vw, 1vw) * 100);
    }

    .refunds .table-content table td:nth-child(1),
    .refunds .table-content table th:nth-child(1) {
        position: sticky;
        left: -2px;
        z-index: 3;
        background-color: inherit;
    }
}

@media screen and (width <= 750px) {
    .refunds .table-content table td,
    .refunds .table-content table th {
        width: 150px;
    }
}